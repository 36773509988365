{
  "name": "designage-cms",
  "version": "2.21.2",
  "scripts": {
    "ng": "nx",
    "start": "nx serve designage --configuration development",
    "wt": "nx serve watchtower --configuration development",
    "staging": "nx serve designage --configuration staging",
    "prod": "nx serve designage --configuration production",
    "prodev": "nx serve designage --configuration prodev",
    "wt-staging": "nx serve watchtower --configuration staging",
    "build": "nx build",
    "clean": "rm -rf node_modules",
    "reinstall": "npm run clean && npm install",
    "build:staging": "nx build designage --configuration staging  --no-cloud",
    "build:prodev": "nx build designage --configuration prodev",
    "build:prod": "nx build designage --configuration production --no-cloud",
    "server": "nx build designage --configuration staging && angular-http-server -p 4200 --path dist/apps/designage",
    "test": "nx test",
    "lint": "nx exec -- npm run _lint -- ",
    "e2e": "nx e2e",
    "gql-generate": "graphql-codegen --config codegen.yml",
    "generate:gql-types": "NODE_TLS_REJECT_UNAUTHORIZED=0 graphql-codegen --config codegen.ts",
    "generate:gql-types-old": "graphql-codegen --config codegen.yml",
    "compodoc": "npx compodoc -p tsconfig.base.json src -n Designage2 --theme postmark -s",
    "nx": "nx",
    "affected:apps": "nx affected:apps",
    "affected:libs": "nx affected:libs",
    "affected:build": "nx affected:build",
    "affected:e2e": "nx affected:e2e",
    "affected:test": "nx affected:test",
    "affected:lint": "nx affected:lint",
    "affected:dep-graph": "nx affected:dep-graph",
    "affected": "nx affected",
    "format": "nx format:write",
    "format:write": "nx format:write",
    "format:check": "nx format:check",
    "update": "nx update @nrwl/workspace",
    "update:check": "nx update",
    "dep-graph": "nx dep-graph",
    "workspace-schematic": "nx workspace-schematic",
    "help": "nx help",
    "_lint": "nx workspace-lint && nx lint"
  },
  "repository": {
    "type": "git",
    "url": "git+https://bitbucket.org/desquare/designage2-webclient-cms.git"
  },
  "private": true,
  "dependencies": {
    "@angular-slider/ngx-slider": "^17.0.2",
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/forms": "^17.3.12",
    "@angular/localize": "^17.3.12",
    "@angular/material": "^17.3.10",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@angular/service-worker": "^17.3.12",
    "@apollo/client": "^3.9.10",
    "@auth0/auth0-angular": "^2.2.3",
    "@auth0/auth0-spa-js": "1.11.0",
    "@cloudinary/ng": "^2.0.2",
    "@cloudinary/url-gen": "^1.19.0",
    "@mapbox/mapbox-gl-geocoder": "^5.0.3",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ngrx/operators": "^17.2.0",
    "@ngrx/signals": "^17.2.0",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@nitsanzo/ngx-zendesk-webwidget": "^17.0.1",
    "@popperjs/core": "^2.11.8",
    "@vvo/tzdb": "^6.108.0",
    "angular-animations": "^0.11.0",
    "angular-split": "^17.2.0",
    "apollo-angular": "^7.0.2",
    "bootstrap": "^5.3.3",
    "core-js": "^3.34.0",
    "country-list": "^2.3.0",
    "crypto-js": "^4.2.0",
    "echarts": "^5.5.1",
    "graphql": "^15.8.0",
    "graphql-sse": "^2.5.3",
    "graphql-tag": "^2.12.4",
    "jspdf": "^2.3.1",
    "jwt-decode": "^4.0.0",
    "lodash": "^4.17.21",
    "luxon": "^3.4.3",
    "mapbox-gl": "^2.10.0",
    "moment": "^2.29.4",
    "ng-click-outside2": "^12.0.0",
    "ng2-validation": "^4.2.0",
    "ngx-clipboard": "^16.0.0",
    "ngx-color-picker": "^15.0.0",
    "ngx-echarts": "^17.2.0",
    "ngx-mapbox-gl": "^10.0.0",
    "ngx-mask": "^16.4.2",
    "ngx-moment": "^6.0.2",
    "ngx-mqtt": "^17.0.0",
    "ngx-skeleton-loader": "^9.0.0",
    "ngx-toastr": "^19.0.0",
    "ngx-webstorage": "^13.0.1",
    "ngxtension": "^4.0.0",
    "path": "^0.12.7",
    "rrule": "^2.6.8",
    "rxjs": "^7.4.6",
    "smooth-scrollbar": "^8.6.2",
    "stream": "0.0.2",
    "subsink": "^1.0.2",
    "thumbler": "^1.1.2",
    "tiny-slider": "2.9.2",
    "ts-luxon": "^4.4.0",
    "tslib": "^2.6.2",
    "unsplash-js": "^7.0.15",
    "vest": "^5.4.6",
    "xng-breadcrumb": "^11.0.0",
    "zone.js": "~0.14.8"
  },
  "devDependencies": {
    "@angular-devkit/architect": "^0.1703.8",
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-devkit/core": "^17.3.8",
    "@angular-devkit/schematics": "^17.3.8",
    "@angular-eslint/eslint-plugin": "^16.2.0",
    "@angular-eslint/eslint-plugin-template": "^16.2.0",
    "@angular-eslint/template-parser": "^16.2.0",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.12",
    "@angular/language-service": "^17.3.12",
    "@cesdk/cesdk-js": "^1.42.0",
    "@commitlint/cli": "^9.1.2",
    "@commitlint/config-conventional": "^9.1.2",
    "@graphql-codegen/cli": "^5.0.0",
    "@graphql-codegen/client-preset": "^4.1.0",
    "@graphql-codegen/fragment-matcher": "^5.0.0",
    "@graphql-codegen/introspection": "^4.0.0",
    "@graphql-codegen/typescript": "^4.0.1",
    "@graphql-codegen/typescript-apollo-angular": "^4.0.0",
    "@graphql-codegen/typescript-operations": "^4.0.1",
    "@nx/angular": "19.7.4",
    "@nx/eslint": "^19.7.4",
    "@nx/eslint-plugin": "19.7.4",
    "@nx/workspace": "19.7.4",
    "@schematics/angular": "^16.2.0",
    "@storybook/addon-essentials": "6.5.16",
    "@storybook/angular": "^7.6.3",
    "@storybook/builder-webpack5": "6.5.16",
    "@storybook/manager-webpack5": "6.5.16",
    "@swc-node/register": "^1.10.9",
    "@swc/core": "^1.7.26",
    "@types/country-list": "^2.1.0",
    "@types/crypto-js": "^3.1.47",
    "@types/jest": "29.4.4",
    "@types/jwt-decode": "^2.2.1",
    "@types/lodash": "^4.14.170",
    "@types/luxon": "^3.3.2",
    "@types/mapbox-gl": "^2.3.3",
    "@types/node": "^18.6.4",
    "@types/readable-stream": "^2.3.10",
    "@types/sharedworker": "0.0.29",
    "@types/sort-array": "^4.1.2",
    "@types/sortablejs": "^1.10.6",
    "@types/uuid": "^3.4.9",
    "@types/ws": "^7.4.7",
    "codelyzer": "^6.0.0",
    "conventional-changelog-conventionalcommits": "^4.6.0",
    "cypress": "^6.0.1",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "9.0.0",
    "eslint-plugin-cypress": "2.15.1",
    "eslint-plugin-import": "2.27.5",
    "eslint-plugin-jsdoc": "46.4.2",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "gsap": "^3.12.3",
    "husky": "^4.3.8",
    "lint-staged": "^10.5.4",
    "npm-run-all": "^4.1.5",
    "nx": "19.7.4",
    "prettier": "3.5.2",
    "react": "^17.0.2",
    "react-dom": "^17.0.2",
    "sort-array": "^5.0.0",
    "ts-node": "~10.9.2",
    "typescript": "~5.4.5",
    "webpack": "5.89.0"
  },
  "optionalDependencies": {
    "@nx/nx-darwin-arm64": "17.2.3",
    "@nx/nx-linux-x64-gnu": "17.2.3"
  },
  "nx": {
    "targets": {
      "prod": {
        "outputs": [
          "{projectRoot}/dist"
        ]
      },
      "build": {
        "outputs": [
          "{projectRoot}/dist"
        ]
      },
      "build:staging": {
        "outputs": [
          "{projectRoot}/dist/staging"
        ]
      },
      "build:prod": {
        "outputs": [
          "{projectRoot}/dist/prod"
        ]
      }
    },
    "defaultProject": "designage"
  }
}
